import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/foreign-admits/src/app/auth/auth.guard';
import { AuthorizationGuard } from 'projects/foreign-admits/src/app/auth/authorization.guard';
const routes: Routes = [

  {
    path: 'dashboard',
    data: {
      title: "Dashboard",
      preload: true
    },
    canActivate: [AuthGuard, AuthorizationGuard],
    canActivateChild: [AuthGuard, AuthorizationGuard],
    loadChildren: () =>
      import(`./dashboard/dashboard.module`).then((m) => m.DashboardModule),
  },
  {
    path: '',
    data: {
      title: "Home",
      preload: true
    },
    loadChildren: () =>
      import(`../app/front-end/front-end.module`).then((m) => m.FrontEndModule),
  },
  {
    path: '',
    data: {
      preload: true
    },
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AOnAuthModule),
  },
  {
    path: '**',
    data: {
      preload: true
    },
    loadChildren: () =>
      import('./../../../apna-lib/src/lib/components/page404/page404.module').then((m) => m.Page404Module),
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
