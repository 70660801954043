import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, isDevMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServicesModule } from 'projects/apna-lib/src/lib/services/services.module';
import { environment } from '../environments/environment';

// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { MatDialogModule } from '@angular/material/dialog';
import { ConfigService } from 'projects/apna-lib/src/lib/services/config.service';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { CustomOfflineComponent } from './custom-offline/custom-offline.component';
import { SuprSendInboxModule } from '@suprsend/ngx-inbox';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { suprSendKeys } from './constants/suprsend.enum';

function initializeApp(config: ConfigService): any {
  // config.getAllConfig()
  return () => {
    return new Promise((resolve, reject) => {
      config.getAllConfig().then(data => resolve(true))
    })
  }
}
@NgModule({
  declarations: [
    AppComponent,
    CustomOfflineComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    ServicesModule.forRoot(environment),
    NgxSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    ToastNoAnimationModule.forRoot(),
  
    SuprSendInboxModule.forRoot({
      workspaceKey:suprSendKeys.workspaceKey,
      workspaceSecret: suprSendKeys.workspaceSecret,
  }),
    ServiceWorkerModule.register('/serviceworker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    // ConfigService,
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'INR' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [ConfigService]
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: location.search.includes('') }),
    },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }