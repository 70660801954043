import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router';
import { UserIdentityService } from 'projects/apna-lib/src/lib/services/user-identity.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  // Inject Router so we can hand off the user to the Login Page
  constructor(
    private router: Router,
    private userIdentity: UserIdentityService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // console.log(next.routeConfig)

    if (next.routeConfig.path.includes('logout')) {
      return true;
    }

    if (this.userIdentity.isValidToken()) {
      // Token from the LogIn is avaiable, so the user can pass to the route
      if (next.routeConfig.path.includes('auth')) {
        this.router.navigate(['dashboard']);
        return false;
      }
      return true;
    } else if (this.userIdentity.isValidRefreshToken()) {
      return this.userIdentity.refreshToken();
    } else {

      // Token from the LogIn is not avaible because something went wrong or the user wants to go over the url to the site
      // Hands the user to the LogIn page
      if (!this.userIdentity.isValidToken()) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('ttl');
        localStorage.removeItem('created');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('roleName');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('signUpComplete');
        localStorage.removeItem('LoginComplete');
      }

      // console.log('You are currently not logged in, please provide Login!');
      if (!next.routeConfig.path.includes('auth') && localStorage.getItem('token') !== null) {
        this.router.navigate(['/']);
        return false;
      }
      else if(!next.routeConfig.path.includes('auth') && localStorage.getItem('token') === null){
        this.router.navigate(['/'], {queryParams: {logout:true}});
        return false;
      }
      return true;
    }

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // console.log(next.routeConfig)

    if (childRoute.routeConfig.path.includes('logout')) {
      return true;
    }

    if (localStorage.getItem('token') && this.userIdentity.isValidToken()) {
      // Token from the LogIn is avaiable, so the user can pass to the route
      if (childRoute.routeConfig.path.includes('auth')) {
        this.router.navigate(['dashboard']);
        return false;
      }
      return true;
    } else if (localStorage.getItem('refreshToken') && this.userIdentity.isValidRefreshToken()) {
      return this.userIdentity.refreshToken();
    } else {

      // Token from the LogIn is not avaible because something went wrong or the user wants to go over the url to the site
      // Hands the user to the LogIn page
      if (!this.userIdentity.isValidToken()) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('ttl');
        localStorage.removeItem('created');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('roleName');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('signUpComplete');
        localStorage.removeItem('LoginComplete');
      }

      // console.log('You are currently not logged in, please provide Login!');
      if (!childRoute.routeConfig.path.includes('auth') && localStorage.getItem('token') !== null) {
        this.router.navigate(['/']);
        return false;
      }
      else if(!childRoute.routeConfig.path.includes('auth') && localStorage.getItem('token') === null){
        this.router.navigate(['/'], {queryParams: {logout:true}});
        return false;
      }
      return true;
    }

  }
}
