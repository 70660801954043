import { ApplicationRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { ConfigService } from 'projects/apna-lib/src/lib/services/config.service';
import { UserIdentityService } from 'projects/apna-lib/src/lib/services/user-identity.service';
import { concat, interval, Subject } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { Platform } from '@angular/cdk/platform';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
@Component({
  selector: 'fa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isOnline: boolean;
  title = 'ForeignAdmits';
  modalVersion: boolean;
  modalPwaEvent: any;
  modalPwaPlatform: string | undefined;
  constructor(
    private config: ConfigService,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    public snackBar: MatSnackBar,
  ) {
    this.isOnline = false;
    this.modalVersion = false;
    this.updateOnlineStatus();
    if (environment?.production) {
      this.checkUpdate();
      appRef.isStable.pipe(
        filter(stable => stable)
      )
    }

  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  public ngOnInit(): void {
    // this.isOnline=true
    if (environment?.production) {
      if (typeof window !== 'undefined') {
        window.addEventListener('online', this.updateOnlineStatus.bind(this));
        window.addEventListener('offline', this.updateOnlineStatus.bind(this));
      }
    }


  }
  checkUpdate() {

    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(8 * 60 * 60 * 1000);

        timeInterval.subscribe(() => {
          this.swUpdate.checkForUpdate().then(() => console.log('checked'));
        });
      }
    });
  }
  private updateOnlineStatus(): void {
    if (typeof window !== 'undefined') {
      this.isOnline = window.navigator.onLine;
    }

    if (this.isOnline && typeof document !== 'undefined') {
      (document.querySelector('body') as any).style = '';
    } else if (typeof document !== 'undefined') {
      (document.querySelector('body') as any).style = ' ';
    }
  }
  public closeVersion(): void {
    this.modalVersion = false;
  }

  private loadModalPwa(): void {
    if (this.platform.ANDROID) {
      if (typeof window !== 'undefined') {
        window.addEventListener('beforeinstallprompt', (event: any) => {
          event.preventDefault();
          this.modalPwaEvent = event;
          this.modalPwaPlatform = 'ANDROID';
        });
      }

    }

    if (this.platform.IOS && this.platform.SAFARI) {
      const isInStandaloneMode = ('minimal-ui' in window.navigator) && ((<any>window.navigator)['minimal-ui']);
      if (!isInStandaloneMode) {
        this.modalPwaPlatform = 'IOS';
      }
    }
    if (this.platform.isBrowser) {
      const isInStandaloneMode = ('minimal-ui' in window.navigator) && ((<any>window.navigator)['minimal-ui']);
      if (!isInStandaloneMode) {
        this.modalPwaPlatform = 'Browser';
      }
    }
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  public closePwa(): void {
    this.modalPwaPlatform = undefined;
  }
}
