
let suprsendKeys: any
if (typeof window !== "undefined" && window.location.host.includes('localhost') || typeof window !== "undefined" && window.location.host.includes('dev') || typeof window !== "undefined" && window.location.host.includes('qa')) {
    suprsendKeys = {
        workspaceKey: "5ZBulonOcPkbrcZweRSe",
        workspaceSecret: "Gl6LFNwLiXnTvb9DTuPO"
    }
}
else if(typeof window !== "undefined" && window.location.host.includes('uat') || typeof window !== "undefined" && window.location.host.includes('foreignadmits') ) {
    suprsendKeys = {
        workspaceKey: "RR0VdLRA5igxV35G3Qw0",
        workspaceSecret: "ypMF9ELXHFEP4LuQ5ZGj"
    }
}
export const suprSendKeys = {
    workspaceKey: suprsendKeys?.workspaceKey,
    workspaceSecret: suprsendKeys?.workspaceSecret
}
