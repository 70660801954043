import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/foreign-admits/src/environments/environment';
import { Breadcrumb } from '../components/breadcrumb/interfaces/breadcrumb';

export interface SeoObj {
  title: string,
  metaKey: string | string[],
  metaDesc: string,
  metaImage?: string,
  pageUrl?: string
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  public title: string;
  private metaImageUrl: string;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
   
    this.title = route.snapshot.data.title;
    this.metaImageUrl = `${environment.path}assets/logo/foreignadmits/foreignadmits-dark.png`;
  }

  addSeo(title: string, metaKeywords: string | string[], metaDesc: string, metaImage?: string, pageUrl?: string) {
    let metaKey: any = metaKeywords;
    let metaKeyStr: string = metaKey?.join ? metaKey.join(',') : String(metaKey);
    this.titleService.setTitle(title);
    this.meta.addTags([
      { name: 'keywords', content: metaKeyStr },
      { name: 'description', content: metaDesc },
      { name: 'og:title', content: title },
      { name: 'og:description', content: metaDesc },
      { name: 'og:url', content: environment.path + pageUrl },
      { name: 'og:image', content: metaImage || this.metaImageUrl },
    ]);
  }

  addSeoObj(seo: SeoObj) {
    if (seo && seo.title) {
      this.addSeo(seo.title, seo.metaKey, seo.metaDesc, seo['metaImg'] || seo.metaImage, seo.pageUrl);
    }
  }

  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label =   route.routeConfig && route.routeConfig.data ? route.routeConfig.data.title : '';
    let path =    route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    let search=   route?.snapshot?.params?route?.snapshot?.params?.searchTerm :'';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    
    const isDynamicRoute = lastRoutePart.startsWith(':');

    if(isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
   
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.data.title;
      search=route.snapshot.params.searchTerm;
    }
    
    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: Breadcrumb = {
        label: label,
        url: nextUrl,
        search:search,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
        //If we are not on our current path yet,
        //there will be more children to look after, to build our breadcumb
      
        return this.createBreadcrumbs(route.firstChild, nextUrl, newBreadcrumbs);
    } else {
      if (route.snapshot?.data?.seo) {
        this.addSeoObj(route.snapshot.data.seo);
      }
      return newBreadcrumbs;
    }
  }

  

}
