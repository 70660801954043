import { Injectable } from '@angular/core';
import { UserDetails } from '../interfaces/user-details';
@Injectable()
export class AuthService {
  allPermissions = {
    "welcome.student": true,
    "welcome.partner": true,
    "welcome.mentor": true,
    "users": true,
    // Student
    "users.student": true,
    "users.student.add": true,
    "users.student.edit": true,
    "users.student.view": true,
    "users.student.preview": true,
    "users.student.delete": true,
    "users.student.createApplicationLink": true,
    "users.offeredServices": true,
    // Partner
    "users.partner": true,
    "users.partner.add": true,
    "users.partner.edit": true,
    "users.partner.view": true,
    "users.partner.delete": true,

    // Mentor
    "users.mentor": true,
    "users.mentor.add": true,
    "users.mentor.edit": true,
    "users.mentor.view": true,
    "users.mentor.delete": true,

    // Vendor
    "users.vendor": true,
    "users.vendor.add": true,
    "users.vendor.edit": true,
    "users.vendor.view": true,
    "users.vendor.delete": true,

    // Admin
    "users.admin": true,
    "users.admin.add": true,
    "users.admin.edit": true,
    "users.admin.view": true,
    "users.admin.delete": true,

    // User
    "user.dashboard": true,
    "users.user": true,
    "users.user.add": true,
    "users.user.edit": true,
    "users.user.view": true,
    "users.user.delete": true,

    // Vendor User
    "users.vendorUser": true,
    "users.vendorUser.add": true,
    "users.vendorUser.edit": true,
    "users.vendorUser.view": true,
    "users.vendorUser.delete": true,


    // Roles
    "roles": true,
    "roles.add": true,
    "roles.edit": true,
    "roles.view": true,
    "roles.delete": true,

    // Lead
    "leads": true,
    "leads.add": true,
    "leads.edit": true,
    "leads.view": true,
    "leads.delete": true,
    "leads.multiUpdate": true,
    "leads.clickToCall": true,
    "leads.changeStatus": true,
    "leads.changeCallStatus": true,
    "leads.changeAssignedTo": true,
    "leads.changePartner": true,
    "leads.timeline": true,
    "leads.addNotes": true,
    "leads.leadDetails": true,
    "leads.service.add": true,
    "whatsapp.chat": false,

    // Loan Application
    "loanApplications": true,
    "loanApplications.add": true,
    "loanApplications.view": true,
    "loanApplications.edit": true,
    "loanApplications.changeStatus": true,
    "loanApplications.changeCallStatus": true,
    "loanApplications.addNotes": true,
    "loanApplications.timeline": true,
    "loanApplications.clone": true,
    "loanApplications.clickToCall": true,
    "loanApplications.delete": true,
    "loanApplications.syncDocuments": true,
    "loanApplications.changeAssignedTo": true,
    "loanApplications.utmLink": true,
    "loanApplications.copyLink": true,

    // Commission Payout
    "commissionPayout": true,

    // Commission Payout Invoice
    "commissionPayout.invoices": true,
    "commissionPayout.invoices.add": true,
    "commissionPayout.invoices.edit": true,
    "commissionPayout.invoices.view": true,
    "commissionPayout.invoices.delete": true,

    // Commissioin Payout Transaction
    "commissionPayout.transactions.add": true,
    "commissionPayout.transactions.edit": true,
    "commissionPayout.transactions.view": true,
    "commissionPayout.transactions.delete": true,

    // Referral
    "commissionPayout.referral": true,
    "commissionPayout.referral.view": true,

    // Service Module 
    "services": true,
    "services.add": true,
    "services.edit": true,
    "services.view": true,
    "services.delete": true,

    // Offered service

    // Session Module 
    "sessions": true,
    "sessions.add": true,
    "sessions.edit": true,
    "sessions.view": true,
    "sessions.delete": true,


    // Configuration Module 
    "configurations": true,
    "configurations.add": true,
    "configurations.edit": true,
    "configurations.view": true,
    "configurations.delete": true,

    // Website Page
    "website": true,
    "website.websitePages": true,
    "website.websitePages.add": true,
    "website.websitePages.edit": true,
    "website.websitePages.view": true,
    "website.websitePages.delete": true,

    // Website Category
    "website.websiteCategories": true,
    "website.websiteCategories.add": true,
    "website.websiteCategories.edit": true,
    "website.websiteCategories.view": true,
    "website.websiteCategories.delete": true,

    // Website Menu
    "website.websiteMenus": true,
    "website.websiteMenus.add": true,
    "website.websiteMenus.edit": true,
    "website.websiteMenus.view": true,
    "website.websiteMenus.delete": true,

    // Website Domain
    "website.websiteDomains": true,
    "website.websiteDomains.add": true,
    "website.websiteDomains.edit": true,
    "website.websiteDomains.view": true,
    "website.websiteDomains.delete": true,

    // Website Widget
    "website.websiteWidgets": true,
    "website.websiteWidgets.add": true,
    "website.websiteWidgets.edit": true,
    "website.websiteWidgets.view": true,
    "website.websiteWidgets.delete": true,

    //  slots
    "slots": true,
    "slots.add": true,
    "slots.edit": true,
    "slots.view": true,
    "slots.delete": true,
    "mentor": true,

    // Meetings Module 
    "meeting": true,
    "meeting.add": true,
    "meeting.edit": true,
    "meeting.view": true,
    "meeting.delete": true,


    // Meetings Module 
    "mentor.masterClass": true,
    "mentor.masterClass.add": true,
    "mentor.masterClass.edit": true,
    "mentor.masterClass.view": true,
    "mentor.masterClass.delete": true,

    //Appointment
    "appointment": true,
    "appointment.add": true,
    "appointment.edit": true,
    "appointment.view": true,
    "appointment.delete": true,
    "appointment.accept": true,
    "appointment.reject": true,
    "appointment.reschedule": true,


    // Masterclass Module 
    "masterClass": true,
    "masterClass.add": true,
    "masterClass.edit": true,
    "masterClass.view": true,
    "masterClass.delete": true,

    // Testimonials Module
    "testimonial": true,
    "testimonial.add": true,
    "testimonial.edit": true,
    "testimonial.view": true,
    "testimonial.delete": true,

    // Notifications Module
    "notification": true,
    "notification.notificationTemplate": true,
    "notification.notificationTemplate.add": true,
    "notification.notificationTemplate.edit": true,
    "notification.notificationTemplate.view": true,
    "notification.notificationTemplate.delete": true,

    // Interview Module
    "interviewPreparation": false,
    "interviewPreparation.add": false,
    "interviewPreparation.edit": false,
    "interviewPreparation.view": false,
    "interviewPreparation.delete": false,
    //udget Estimator

  };
  roleBasesAccess: any = {
    partner: {
      "welcome.partner": true,
      "users": false,
      // Users
      "users.student": true,
      "users.user": true,
      "user.dashboard": true,
      "users.student.edit": false,
      "users.student.createApplicationLink": false,
      // Loan Applications 
      "loanApplications": true,
      "loanApplications.add": true,
      "loanApplications.edit": true,
      "loanApplications.view": true,
      "loanApplications.utmLink": true,
      "loanApplications.copyLink": true,
      "loanApplications.addNotes": true,
      // Leads 
      "leads": true,
      "leads.add": false,
      "leads.edit": false,
      "leads.view": true,
      "leads.delete": false,
      "leads.multiUpdate": false,
      "leads.clickToCall": false,
      "leads.changeStatus": false,
      "leads.changeCallStatus": false,
      "leads.changeAssignedTo": false,
      "leads.changePartner": false,
      "leads.timeline": false,
      "leads.addNotes": false,
      "leads.leadDetails": false,
      "leads.leadUser": true,
      "leads.admin": false,
      'leads.partner': true,
      "leads.service.add": true,
      // Commission Payout
      "commissionPayout": true,
      "commissionPayout.transactions": true,
      "commissionPayout.invoices": true,
      "commissionPayout.referral.view": true,
      "commissionPayout.transactions.delete": false,
      "users.offeredServices": true,
      // Meetings Module 
      "meeting": false,
      "meeting.start": false,
      "meeting.add": false,
      "meeting.edit": false,
      "meeting.view": false,
      "meeting.delete": false,

      "users.partner.student": true,
      "user.partner": true,
      "users.student.preview": true,
      //interview Preparation
      "interviewPreparation": true,
      "interviewPreparation.add": false,
      "interviewPreparation.edit": false,
      "interviewPreparation.view": true,
      "interviewPreparation.delete": false,

    },
    student: {
      "welcome.student": true,
      "loanApplications.add": true,
      "loanApplications": true,
      "loanApplications.view": true,
      "mentor": false,
      "user.dashboard": true,
      "commissionPayout": true,
      "commissionPayout.referral": true,
      "forexTransactions": true,
      "users.student.preview": false,
      "users.offeredServices": true,
      // Leads 
      "leads": true,
      "leads.add": false,
      "leads.edit": false,
      "leads.view": true,
      "leads.delete": false,
      "leads.multiUpdate": false,
      "leads.clickToCall": false,
      "leads.changeStatus": false,
      "leads.changeCallStatus": false,
      "leads.changeAssignedTo": false,
      "leads.changePartner": false,
      "leads.timeline": false,
      "leads.addNotes": false,
      "leads.leadDetails": false,
      "leads.leadUser": true,
      "leads.admin": false,
      "leads.service.add": true,

      // Meetings Module 
      "meeting": false,

      "meeting.start": true,
      "meeting.add": false,
      "meeting.edit": false,
      "meeting.view": true,
      "meeting.delete": false,

      //Appointment
      "appointment": false,
      "appointment.add": false,
      "appointment.edit": false,
      "appointment.view": true,
      "appointment.delete": false,
      "appointment.accept": false,
      "appointment.reject": false,
      "appointment.reschedule": false,
      //interview Preparation
      "interviewPreparation": true,
      "interviewPreparation.add": false,
      "interviewPreparation.edit": false,
      "interviewPreparation.view": true,
      "interviewPreparation.delete": false,
      //commission payout
      "commissionPayout.referral.view": false,
      // Offered service
      //budget Estimator

    },
    mentor: {
      "welcome.mentor": true,
      "sessions": true,
      "users": false,
      "user.dashboard": true,
      "users.student": false,
      "commissionPayout": true,
      "commissionPayout.referral": true,
      "mentor": true,
      
      // Meetings Module 
      "meeting": true,
      "meeting.start": true,
      "meeting.add": true,
      "meeting.edit": true,
      "meeting.view": true,
      "meeting.delete": false,

      //Appointment
      "appointment": true,
      "appointment.add": true,
      "appointment.edit": true,
      "appointment.view": true,
      "appointment.delete": true,
      "appointment.accept": true,
      "appointment.reject": true,
      "appointment.reschedule": true,
      //commission Payout
      "commissionPayout.referral.view": true,
      //budget Estimator

    },
    admin: {
      "users": true,
      "user.dashboard": true,
      "users.student": true,
      "users.student.edit": false,
      "users.student.delete": false,
      "users.student.preview": true,
      "users.student.view": false,
      "users.partner": true,
      "users.vendor": true,
      "users.mentor": true,
      "users.admin": true,
      "users.user": true,
      "users.offeredServices": true,
      "loanApplications": true,
      "loanApplications.add": true,
      "loanApplications.view": true,
      "loanApplications.edit": true,
      "loanApplications.changeStatus": true,
      "loanApplications.changeCallStatus": true,
      "loanApplications.addNotes": true,
      "loanApplications.timeline": true,
      "loanApplications.clone": true,
      "loanApplications.clickToCall": true,
      "loanApplications.delete": true,
      "loanApplications.syncDocuments": true,
      "loanApplications.changeAssignedTo": true,

      // Lead
      "leads": true,
      "leads.add": true,
      "leads.edit": true,
      "leads.view": true,
      "leads.delete": true,
      "leads.multiUpdate": true,
      "leads.clickToCall": true,
      "leads.changeStatus": true,
      "leads.changeCallStatus": true,
      "leads.changeAssignedTo": true,
      "leads.changePartner": true,
      "leads.timeline": true,
      "leads.addNotes": true,
      "leads.leadDetails.view": true,
      "leads.leadDetails": true,
      "leads.leadUser": false,
      "leads.admin": true,
      "leads.service.add": true,
      "commissionPayout": true,
      "commissionPayout.invoices": true,
      "commissionPayout.transactions": true,
      "commissionPayout.transactions.delete": true,
      "whatsapp.chat": true,

      //Appointment
      "appointment": false,
      "appointment.add": false,
      "appointment.edit": false,
      "appointment.view": false,
      "appointment.delete": false,
      "appointment.accept": false,
      "appointment.reject": false,
      "appointment.reschedule": false,

      //  slots
      "slots": false,
      "slots.add": false,
      "slots.edit": false,
      "slots.view": false,
      "slots.delete": false,
      "mentor": false,

      // Meetings Module 
      "meeting": false,
      "meeting.add": false,
      "meeting.start": false,
      "meeting.edit": false,
      "meeting.view": false,
      "meeting.delete": false,

      // Meetings Module 
      "masterClass": false,
      "masterClass.add": false,
      "masterClass.edit": false,
      "masterClass.view": false,
      "masterClass.delete": false,

      // Testimonials Module
      "testimonial": true,
      "testimonial.add": true,
      "testimonial.edit": true,
      "testimonial.view": true,
      "testimonial.delete": true,
      "forexTransactions": true,

      // Notifications Module
      "notification": true,
      "notification.notificationTemplate": true,
      "notification.notificationTemplate.add": false,
      "notification.notificationTemplate.edit": false,
      "notification.notificationTemplate.view": true,
      "notification.notificationTemplate.delete": false,
      // university
      "university": false,
      "university.view": false,
      "university.add": false,
      "university.edit": false,
      "university.delete": false,

      // location
      "location": false,
      "location.add": false,
      "location.edit": false,
      "location.view": false,
      "location.delete": false,
      //  Course Module
      "course": true,
      "course.add": false,
      "course.view": true,
      "course.edit": false,
      "course.delete": false,

      // commission Payout
      "commissionPayout.referral.view": true,
      // Offered service
      //budget Estimator
      "users.partner.add": true,
      "users.partner.edit": true,
      "users.partner.view": true,
      "users.partner.delete": true,
    },
    superadmin: {
      "users": true,
      "records.multipleDelete": true,
      // Student
      "users.student": true,
      "user.dashboard": true,
      "users.student.add": true,
      "users.student.edit": false,
      "users.student.view": false,
      "users.student.delete": true,
      "users.student.preview": true,
      "users.student.createApplicationLink": false,
      "users.offeredServices": true,
      // Partner
      "users.partner": true,
      "users.partner.add": true,
      "users.partner.edit": true,
      "users.partner.view": true,
      "users.partner.delete": true,

      // Mentor
      "users.mentor": true,
      "users.mentor.add": true,
      "users.mentor.edit": true,
      "users.mentor.view": true,
      "users.mentor.delete": true,

      // Vendor
      "users.vendor": true,
      "users.vendor.add": true,
      "users.vendor.edit": true,
      "users.vendor.view": true,
      "users.vendor.delete": true,

      // Admin
      "users.admin": true,
      "users.admin.add": true,
      "users.admin.edit": true,
      "users.admin.view": false,
      "users.admin.delete": true,

      // User
      "users.user": true,
      "users.user.add": true,
      "users.user.edit": true,
      "users.user.view": true,
      "users.user.delete": true,

      // Vendor User
      "users.vendorUser": true,
      "users.vendorUser.add": true,
      "users.vendorUser.edit": true,
      "users.vendorUser.view": true,
      "users.vendorUser.delete": true,


      // Roles
      "roles": true,
      "roles.add": true,
      "roles.edit": true,
      "roles.view": true,
      "roles.delete": true,

      // Lead
      "leads": true,
      "leads.add": true,
      "leads.edit": true,
      "leads.view": true,
      "leads.delete": true,
      "leads.multiUpdate": true,
      "leads.clickToCall": true,
      "leads.leadDetails.view": true,
      "leads.leadDetails": true,
      "leads.changePartner": true,
      "leads.leadUser": false,
      "leads.admin": true,
      "leads.service.add": true,
      "leads.changeStatus": true,

      // Loan Application
      "loanApplications": true,
      "loanApplications.add": true,
      "loanApplications.edit": true,
      "loanApplications.view": true,
      "loanApplications.delete": true,
      "loanApplications.utmLink": true,
      "loanApplications.syncDocuments": true,
      "loanApplications.copyLink": true,
      "loanApplications.clone": true,
      "loanApplications.clickToCall": true,
      "loanApplications.changeStatus": true,
      "loanApplications.addNotes": true,

      // Commission Payout
      "commissionPayout": true,
      "commissionPayout.referral.view": true,

      // Commission Payout Invoice
      "commissionPayout.invoices": true,
      "commissionPayout.invoices.add": true,
      "commissionPayout.invoices.edit": true,
      "commissionPayout.invoices.view": true,
      "commissionPayout.invoices.delete": true,

      // Commissioin Payout Transaction
      "commissionPayout.transactions": true,
      "commissionPayout.transactions.add": true,
      "commissionPayout.transactions.edit": true,
      "commissionPayout.transactions.view": true,
      "commissionPayout.transactions.delete": true,

      // Referral
      "commissionPayout.referral": true,


      // Service Module 
      "services": true,
      "services.add": true,
      "services.edit": true,
      "services.view": true,
      "services.delete": true,
      // Offered service


      // Session Module 
      "sessions": true,
      "sessions.add": true,
      "sessions.edit": true,
      "sessions.view": true,
      "sessions.delete": true,


      // Configuration Module 
      "configurations": true,
      "configurations.add": true,
      "configurations.edit": true,
      "configurations.view": true,
      "configurations.delete": true,

      // Website Page
      "website": true,
      "website.websitePages": true,
      "website.websitePages.add": true,
      "website.websitePages.edit": true,
      "website.websitePages.view": true,
      "website.websitePages.delete": true,

      // Website Category
      "website.websiteCategories": true,
      "website.websiteCategories.add": true,
      "website.websiteCategories.edit": true,
      "website.websiteCategories.view": true,
      "website.websiteCategories.delete": true,

      // Website Menu
      "website.websiteMenus": true,
      "website.websiteMenus.add": true,
      "website.websiteMenus.edit": true,
      "website.websiteMenus.view": true,
      "website.websiteMenus.delete": true,

      // Website Domain
      "website.websiteDomains": true,
      "website.websiteDomains.add": true,
      "website.websiteDomains.edit": true,
      "website.websiteDomains.view": true,
      "website.websiteDomains.delete": true,

      // Website Widget
      "website.websiteWidgets": true,
      "website.websiteWidgets.add": true,
      "website.websiteWidgets.edit": true,
      "website.websiteWidgets.view": true,
      "website.websiteWidgets.delete": true,
      //  slots
      "slots": true,
      "slots.add": true,
      "slots.edit": true,
      "slots.view": true,
      "slots.delete": true,
      "mentor": true,

      // Meetings Module 
      "meeting": true,
      "meeting.add": true,
      "meeting.start": true,
      "meeting.edit": true,
      "meeting.view": true,
      "meeting.delete": true,

      // Meetings Module 
      "mentor.masterClass": true,
      "mentor.masterClass.add": true,
      "mentor.masterClass.edit": true,
      "mentor.masterClass.view": true,
      "mentor.masterClass.delete": true,

      //Appointment
      "appointment": true,
      "appointment.add": true,
      "appointment.edit": true,
      "appointment.view": true,
      "appointment.delete": true,
      "appointment.accept": true,
      "appointment.reject": true,
      "appointment.reschedule": true,


      "masterClass": true,
      "masterClass.add": true,
      "masterClass.edit": true,
      "masterClass.view": true,
      "masterClass.delete": true,

      // Testimonials Module
      "testimonial": true,
      "testimonial.add": true,
      "testimonial.edit": true,
      "testimonial.view": true,
      "testimonial.delete": true,

      "forexTransactions": true,

      // Notifications Module
      "notification": true,
      "notification.notificationTemplate": true,
      "notification.notificationTemplate.add": true,
      "notification.notificationTemplate.edit": true,
      "notification.notificationTemplate.view": true,
      "notification.notificationTemplate.delete": true,

      //  Course Module
      "course": true,
      "course.add": true,
      "course.view": true,
      "course.edit": true,
      "course.delete": true,
      // university add
      "university": true,
      "university.add": true,
      "university.edit": true,
      "university.view": true,
      "university.delete": true,
      //Country
      "country": true,
      "country.add": true,
      "country.edit": true,
      "country.view": true,
      "country.delete": true,
      // location


      // Interview Prepration
      "interviewPreparation": true,
      // location
      "location": true,
      "location.add": true,
      "location.edit": true,
      "location.view": true,
      "location.delete": true,
      //budget Estimator

    },
    user: {
      // Lead
      "leads": true,
      "user.dashboard": false,
      "leads.add": true,
      "leads.edit": true,
      "leads.view": true,
      "leads.delete": true,
      "leads.multiUpdate": true,
      "leads.clickToCall": true,
      "leads.changeStatus": true,
      "leads.changeCallStatus": true,
      "leads.changeAssignedTo": true,
      "leads.changePartner": true,
      "leads.timeline": true,
      "leads.addNotes": true,
      "leads.leadDetails.view": true,
      "leads.leadDetails": true,
      "leads.admin": true,
      "loanApplications": true,
      "loanApplications.view": true,
      "loanApplications.changeStatus": true,
      "loanApplications.changeCallStatus": true,
      "loanApplications.addNotes": true,
      "loanApplications.timeline": true,
      "whatsapp.chat": true,
      // user
      // Student
      "users.student": true,
      "users.student.add": false,
      "users.student.edit": false,
      "users.student.view": false,
      "users.student.delete": false,
      "users.student.preview": true,
      "users.student.createApplicationLink": false,
      "users.offeredServices": true,
      // Partner
      "users.partner": true,
      "users.partner.add": false,
      "users.partner.edit": false,
      "users.partner.view": true,
      "users.partner.delete": false,
      //budget Estimator
      "commissionPayout": true,
      "commissionPayout.transactions": true

    },
    vendorUser: {
      // Loan Applications
      "user.dashboard": false,
      "loanApplicationsVendor": true,
      "loanApplicationsVendor.view": true,
      "loanApplicationsVendor.changeStatus": true,
      "loanApplicationsVendor.changeCallStatus": true,
      "loanApplicationsVendor.addNotes": true,
      "loanApplicationsVendor.timeline": true,
      "tools": false,
      "users.offeredServices": false,
      // Meeting Module
      "meeting": true,
      "meeting.add": false,
      "meeting.start": true,
      "meeting.edit": false,
      "meeting.view": true,
      "meeting.delete": false,
      //budget Estimator

    },
    vendor: {
      // Leads
      "tools": false,
      "loanApplicationsVendor": true,
      "users": true,
      "user.dashboard": true,
      "users.user": true,
      "loanApplications.view": true,
      "loanApplications.changeStatus": true,
      "loanApplications.changeCallStatus": true,
      "loanApplications.addNotes": false,
      "loanApplications.timeline": true,
      "loanApplications.changeAssignedTo": true,
      "users.offeredServices": false,
      // Meeting Module
      "meeting": false,
      "meeting.start": false,
      "meeting.add": false,
      "meeting.edit": false,
      "meeting.view": false,
      "meeting.delete": false,
      // payout
      "commission": false,
      //budget Estimator

    },
    partnerUser: {
      // manage students
      "tools": false,
      "leads": true,
      "leads.partner": true,
      "leads.view": true,
      "users.student": true,
      "commissionPayout": false,
      "users.student.preview": true,
      "users.offeredServices": true,
      "interviewPreparation": true,
      "user.dashboard": false,
      "loanApplications.addNotes": true,
    }
  }
  constructor() { }

  private get userDetails(): UserDetails {
    return JSON.parse(localStorage.getItem('userDetails'))
  }
  public get role(): string {
    if (this.userDetails !== null && this.userDetails.type) {
      return this.userDetails.type;
    }
    else return;
  }

  private get otherDetailsSubmitted() {
    return this.userDetails.isOtherDetailsSubmitted;
  }

  private get permissions() {
    return this.roleBasesAccess[this.role];
  }

  getPermissions() {
    return this.permissions;
  }

  hasPermission(permission: string) {
    return typeof permission === undefined || permission == null || !!this.permissions[permission];
  }


  hasNotPermission(permission: string) {
    return this.permissions[permission] ? false : true;
  }

  /*isUser(role: string) {
    return this.role === role;
  }*/


  isUserRole(role: string | string[]) {
    if (typeof role === "object") {
      return role.includes(this.role);
    } else {
      return this.role === role;
    }
  }

  isOtherDetailsSubmitted() {
    return this.otherDetailsSubmitted;
  }

}
