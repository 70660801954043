import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'fa-custom-offline',
  templateUrl: './custom-offline.component.html',
  styleUrls: ['./custom-offline.component.scss']
})
export class CustomOfflineComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}