import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../foreign-admits/src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class IpConfigService {

  constructor(private http: HttpClient) { }

  getIpInfo(): Observable<any> {
    if (environment?.enableIpInfo) {
      return this.http.get("https://ipinfo.io/json?token=26f10a63f2a8e2");
    } else {
      console.log('IP Info service is disabled in this environment');
      return null;
    }
  }
}
